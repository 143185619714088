import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading-spinner-button-add',
  templateUrl: './loading-spinner-button-add.component.html',
  styleUrls: ['./loading-spinner-button-add.component.scss']
})
export class LoadingSpinnerButtonAddComponent implements OnInit {

  @Input()
  noteForm;

  @Input()
  spinnerLoading;

  @Input()
  documentFormNote;

  constructor() {
  }

  ngOnInit() {
  }

}
