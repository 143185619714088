import { Injectable } from '@angular/core';
@Injectable()
export class AppCommon {
  priorityOptions = [
    {
      name: 'Não urgente',
      value: 'NOT_URGENT'
    },
    {
      name: 'Pouco urgente',
      value: 'LITTLE_URGENT'
    },
    {
      name: 'Urgente',
      value: 'URGENT'
    },
    {
      name: 'Muito urgente',
      value: 'VERY_URGENT'
    },
    {
      name: 'Extr. urgente',
      value: 'EXTREMELY_URGENT'
    },
  ];

  typeOptions: Array<string> = ['Informação', 'Reclamação', 'Elogio', 'Sugestão', 'Crítica', 'Denúncia'];

  priorityEnum(priority) {
    if (priority === 'Não urgente') {
      return 'NOT_URGENT';
    }
    if (priority === 'Pouco urgente') {
      return 'LITTLE_URGENT';
    }
    if (priority === 'Urgente') {
      return 'URGENT';
    }
    if (priority === 'Muito urgente') {
      return 'VERY_URGENT';
    }
    if (priority === 'Extr. urgente') {
      return 'EXTREMELY_URGENT';
    }
  }

}

