/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDeletionRequest = /* GraphQL */ `
  mutation CreateDeletionRequest($user_id: String) {
    createDeletionRequest(user_id: $user_id) {
      id
      name
      date
    }
  }
`;
export const createEmergencyPhones = /* GraphQL */ `
  mutation CreateEmergencyPhones($name: String!, $number: String!) {
    createEmergencyPhones(name: $name, number: $number) {
      name
      number
    }
  }
`;
export const createAgentsObs = /* GraphQL */ `
  mutation CreateAgentsObs(
    $operator_name: String!
    $obs: String
    $user_id: String
  ) {
    createAgentsObs(
      operator_name: $operator_name
      obs: $obs
      user_id: $user_id
    ) {
      operator_name
      user_id
      obs
    }
  }
`;
export const createOccurrenceGroup = /* GraphQL */ `
  mutation CreateOccurrenceGroup($input: GeneralOccurrenceToGroupInput) {
    createOccurrenceGroup(input: $input) {
      id
      city
      icon
      type
      type_name
      sound_alert
      is_ombudsman
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      office_id
      office_name
      last_update
      closed
      external_protocol
      protocol
      last_protocol
      created_by
      occurrences_number
      occurrences_number_open
      geohash
      lat
      lgt
      date_started
      date_closed
      date_to_close
    }
  }
`;
export const createOccurrenceWithImage = /* GraphQL */ `
  mutation CreateOccurrenceWithImage(
    $type: String!
    $description: String
    $lat: Float!
    $lgt: Float!
    $address: String
    $image: String
    $protocol: String
  ) {
    createOccurrenceWithImage(
      type: $type
      description: $description
      lat: $lat
      lgt: $lgt
      address: $address
      image: $image
      protocol: $protocol
    ) {
      id
      protocol
      city
      is_ombudsman
      type
      subject
      description
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      last_update
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      closed
      external_protocol
      geohash
      geo_group_id
      last_protocol
      sound_alert
      date_started
      open_days
      completion_date
      date_to_close
      end_date
      date_closed
      extended
      type_id
      type_name
      office_id
      office_name
      lat
      lgt
      address
      url_image
      icon
      created_by
      user_name
      user_email
      user_phone
      cpf
      identified
    }
  }
`;
export const updateOccurrence = /* GraphQL */ `
  mutation UpdateOccurrence(
    $id: ID!
    $status: String!
    $priority: PRIORITY!
    $note: String
    $closed: Boolean
    $completion_date: String
    $image: String
    $external_protocol: String
    $protocol: String
  ) {
    updateOccurrence(
      id: $id
      status: $status
      priority: $priority
      note: $note
      closed: $closed
      completion_date: $completion_date
      image: $image
      external_protocol: $external_protocol
      protocol: $protocol
    ) {
      id
      protocol
      city
      is_ombudsman
      type
      subject
      description
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      last_update
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      closed
      external_protocol
      geohash
      geo_group_id
      last_protocol
      sound_alert
      date_started
      open_days
      completion_date
      date_to_close
      end_date
      date_closed
      extended
      type_id
      type_name
      office_id
      office_name
      lat
      lgt
      address
      url_image
      icon
      created_by
      user_name
      user_email
      user_phone
      cpf
      identified
    }
  }
`;
export const updateOccurrencesGrouped = /* GraphQL */ `
  mutation UpdateOccurrencesGrouped(
    $id: ID!
    $status: String!
    $priority: PRIORITY!
    $note: String
    $closed: Boolean
    $completion_date: String
    $image: String
    $updated_from_child_id: String
    $external_protocol: String
    $protocol: String
  ) {
    updateOccurrencesGrouped(
      id: $id
      status: $status
      priority: $priority
      note: $note
      closed: $closed
      completion_date: $completion_date
      image: $image
      updated_from_child_id: $updated_from_child_id
      external_protocol: $external_protocol
      protocol: $protocol
    ) {
      id
      city
      icon
      type
      type_name
      sound_alert
      is_ombudsman
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      office_id
      office_name
      last_update
      closed
      external_protocol
      protocol
      last_protocol
      created_by
      occurrences_number
      occurrences_number_open
      geohash
      lat
      lgt
      date_started
      date_closed
      date_to_close
    }
  }
`;
export const updateOccurrenceProtocol = /* GraphQL */ `
  mutation UpdateOccurrenceProtocol($id: ID!, $protocol: String!) {
    updateOccurrenceProtocol(id: $id, protocol: $protocol) {
      id
      protocol
      city
      is_ombudsman
      type
      subject
      description
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      last_update
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      closed
      external_protocol
      geohash
      geo_group_id
      last_protocol
      sound_alert
      date_started
      open_days
      completion_date
      date_to_close
      end_date
      date_closed
      extended
      type_id
      type_name
      office_id
      office_name
      lat
      lgt
      address
      url_image
      icon
      created_by
      user_name
      user_email
      user_phone
      cpf
      identified
    }
  }
`;
export const updateOccurrenceByProtocol = /* GraphQL */ `
  mutation UpdateOccurrenceByProtocol(
    $city: String!
    $protocol: String!
    $status: String!
    $priority: String!
    $note: String
    $closed: Boolean
    $completion_date: String
  ) {
    updateOccurrenceByProtocol(
      city: $city
      protocol: $protocol
      status: $status
      priority: $priority
      note: $note
      closed: $closed
      completion_date: $completion_date
    ) {
      id
      protocol
      city
      is_ombudsman
      type
      subject
      description
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      last_update
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      closed
      external_protocol
      geohash
      geo_group_id
      last_protocol
      sound_alert
      date_started
      open_days
      completion_date
      date_to_close
      end_date
      date_closed
      extended
      type_id
      type_name
      office_id
      office_name
      lat
      lgt
      address
      url_image
      icon
      created_by
      user_name
      user_email
      user_phone
      cpf
      identified
    }
  }
`;
export const updateVaccineStatus = /* GraphQL */ `
  mutation UpdateVaccineStatus(
    $city: String
    $cognito_user_name: String
    $vaccine_status: String
  ) {
    updateVaccineStatus(
      city: $city
      cognito_user_name: $cognito_user_name
      vaccine_status: $vaccine_status
    )
  }
`;
export const addNoteToOccurrence = /* GraphQL */ `
  mutation AddNoteToOccurrence($id: ID!, $note: String!) {
    addNoteToOccurrence(id: $id, note: $note) {
      id
      protocol
      city
      is_ombudsman
      type
      subject
      description
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      last_update
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      closed
      external_protocol
      geohash
      geo_group_id
      last_protocol
      sound_alert
      date_started
      open_days
      completion_date
      date_to_close
      end_date
      date_closed
      extended
      type_id
      type_name
      office_id
      office_name
      lat
      lgt
      address
      url_image
      icon
      created_by
      user_name
      user_email
      user_phone
      cpf
      identified
    }
  }
`;
export const createOmbudsmanTicket = /* GraphQL */ `
  mutation CreateOmbudsmanTicket(
    $subject: String!
    $description: String
    $type: String!
    $identified: Boolean
  ) {
    createOmbudsmanTicket(
      subject: $subject
      description: $description
      type: $type
      identified: $identified
    ) {
      id
      protocol
      city
      is_ombudsman
      type
      subject
      description
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      last_update
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      closed
      external_protocol
      geohash
      geo_group_id
      last_protocol
      sound_alert
      date_started
      open_days
      completion_date
      date_to_close
      end_date
      date_closed
      extended
      type_id
      type_name
      office_id
      office_name
      lat
      lgt
      address
      url_image
      icon
      created_by
      user_name
      user_email
      user_phone
      cpf
      identified
    }
  }
`;
export const updateOmbudsmanTicket = /* GraphQL */ `
  mutation UpdateOmbudsmanTicket(
    $id: ID!
    $status: String!
    $priority: PRIORITY!
    $note: String
    $closed: Boolean
    $completion_date: String
    $external_protocol: String
  ) {
    updateOmbudsmanTicket(
      id: $id
      status: $status
      priority: $priority
      note: $note
      closed: $closed
      completion_date: $completion_date
      external_protocol: $external_protocol
    ) {
      id
      protocol
      city
      is_ombudsman
      type
      subject
      description
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      last_update
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      closed
      external_protocol
      geohash
      geo_group_id
      last_protocol
      sound_alert
      date_started
      open_days
      completion_date
      date_to_close
      end_date
      date_closed
      extended
      type_id
      type_name
      office_id
      office_name
      lat
      lgt
      address
      url_image
      icon
      created_by
      user_name
      user_email
      user_phone
      cpf
      identified
    }
  }
`;
export const updateOmbudsmanTicketByProtocol = /* GraphQL */ `
  mutation UpdateOmbudsmanTicketByProtocol(
    $city: String!
    $protocol: String!
    $status: String!
    $priority: String!
    $note: String
    $closed: Boolean
    $completion_date: String
  ) {
    updateOmbudsmanTicketByProtocol(
      city: $city
      protocol: $protocol
      status: $status
      priority: $priority
      note: $note
      closed: $closed
      completion_date: $completion_date
    ) {
      id
      protocol
      city
      is_ombudsman
      type
      subject
      description
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      last_update
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      closed
      external_protocol
      geohash
      geo_group_id
      last_protocol
      sound_alert
      date_started
      open_days
      completion_date
      date_to_close
      end_date
      date_closed
      extended
      type_id
      type_name
      office_id
      office_name
      lat
      lgt
      address
      url_image
      icon
      created_by
      user_name
      user_email
      user_phone
      cpf
      identified
    }
  }
`;
export const updateOmbudsmanTicketProtocol = /* GraphQL */ `
  mutation UpdateOmbudsmanTicketProtocol($id: ID!, $protocol: String!) {
    updateOmbudsmanTicketProtocol(id: $id, protocol: $protocol) {
      id
      protocol
      city
      is_ombudsman
      type
      subject
      description
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      last_update
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      closed
      external_protocol
      geohash
      geo_group_id
      last_protocol
      sound_alert
      date_started
      open_days
      completion_date
      date_to_close
      end_date
      date_closed
      extended
      type_id
      type_name
      office_id
      office_name
      lat
      lgt
      address
      url_image
      icon
      created_by
      user_name
      user_email
      user_phone
      cpf
      identified
    }
  }
`;
export const addNoteToTicket = /* GraphQL */ `
  mutation AddNoteToTicket($id: ID!, $note: String!) {
    addNoteToTicket(id: $id, note: $note) {
      id
      protocol
      city
      is_ombudsman
      type
      subject
      description
      historic {
        note
        date
        priority
        status
        external_protocol
        operator_id
        operator_name
        completion_date
        closed
        url_image
        last_protocol
        protocol
      }
      last_update
      notes {
        note
        date
        public
        operator_name
        operator_id
      }
      status
      priority
      priority_number
      closed
      external_protocol
      geohash
      geo_group_id
      last_protocol
      sound_alert
      date_started
      open_days
      completion_date
      date_to_close
      end_date
      date_closed
      extended
      type_id
      type_name
      office_id
      office_name
      lat
      lgt
      address
      url_image
      icon
      created_by
      user_name
      user_email
      user_phone
      cpf
      identified
    }
  }
`;
export const createOccurrenceType = /* GraphQL */ `
  mutation CreateOccurrenceType(
    $name: String!
    $office_id: String!
    $office_name: String!
    $group: Boolean
    $sound_alert: Boolean
    $monitor: Boolean
    $priority: PRIORITY!
    $icon: String!
  ) {
    createOccurrenceType(
      name: $name
      office_id: $office_id
      office_name: $office_name
      group: $group
      sound_alert: $sound_alert
      monitor: $monitor
      priority: $priority
      icon: $icon
    ) {
      id
      icon
      city
      name
      office_id
      office_name
      sound_alert
      group
      monitor
      priority
      integration {
        token_header
        token
        enabled
        url
        api
        body_new
        body_update
      }
    }
  }
`;
export const updateOccurrenceType = /* GraphQL */ `
  mutation UpdateOccurrenceType(
    $id: ID!
    $name: String!
    $office_id: String!
    $office_name: String!
    $group: Boolean
    $monitor: Boolean
    $sound_alert: Boolean
    $priority: PRIORITY!
    $icon: String!
  ) {
    updateOccurrenceType(
      id: $id
      name: $name
      office_id: $office_id
      office_name: $office_name
      group: $group
      monitor: $monitor
      sound_alert: $sound_alert
      priority: $priority
      icon: $icon
    ) {
      id
      icon
      city
      name
      office_id
      office_name
      sound_alert
      group
      monitor
      priority
      integration {
        token_header
        token
        enabled
        url
        api
        body_new
        body_update
      }
    }
  }
`;
export const updateOccurrenceTypeOmbudsman = /* GraphQL */ `
  mutation UpdateOccurrenceTypeOmbudsman(
    $group: Boolean!
    $priority: PRIORITY!
    $icon: String!
  ) {
    updateOccurrenceTypeOmbudsman(
      group: $group
      priority: $priority
      icon: $icon
    ) {
      id
      icon
      city
      name
      office_id
      office_name
      sound_alert
      group
      monitor
      priority
      integration {
        token_header
        token
        enabled
        url
        api
        body_new
        body_update
      }
    }
  }
`;
export const createOccurrenceStatus = /* GraphQL */ `
  mutation CreateOccurrenceStatus($name: String!) {
    createOccurrenceStatus(name: $name) {
      city
      name
      enabled
    }
  }
`;
export const createUserPriority = /* GraphQL */ `
  mutation CreateUserPriority($name: String!) {
    createUserPriority(name: $name) {
      id
      city
      name
      enabled
    }
  }
`;
export const updateOccurrenceStatus = /* GraphQL */ `
  mutation UpdateOccurrenceStatus($new_name: String!, $old_name: String!) {
    updateOccurrenceStatus(new_name: $new_name, old_name: $old_name) {
      city
      name
      enabled
    }
  }
`;
export const updateUserPriority = /* GraphQL */ `
  mutation UpdateUserPriority($new_name: String!, $old_name: String!) {
    updateUserPriority(new_name: $new_name, old_name: $old_name) {
      id
      city
      name
      enabled
    }
  }
`;
export const enableOccurrenceStatus = /* GraphQL */ `
  mutation EnableOccurrenceStatus($name: String!, $enable: Boolean!) {
    enableOccurrenceStatus(name: $name, enable: $enable) {
      city
      name
      enabled
    }
  }
`;
export const enableUserPriority = /* GraphQL */ `
  mutation EnableUserPriority($name: String!, $enable: Boolean!) {
    enableUserPriority(name: $name, enable: $enable) {
      id
      city
      name
      enabled
    }
  }
`;
export const enableVaccinationPoint = /* GraphQL */ `
  mutation EnableVaccinationPoint(
    $id: String
    $name: String!
    $enable: Boolean!
  ) {
    enableVaccinationPoint(id: $id, name: $name, enable: $enable) {
      id
      name
      enabled
      quantityOfVaccines
      quantityOfAgents
      peopleToVaccinate
      vaccinatedPeople
      quantityPeoplesOnPoint
      low_age
      high_age
      address
      address_number
      lat
      long
    }
  }
`;
export const integrationOccurrenceType = /* GraphQL */ `
  mutation IntegrationOccurrenceType(
    $id: ID!
    $enabled: Boolean!
    $token_header: String
    $token: String
    $url: String
    $api: String!
    $body_new: String
    $body_update: String
  ) {
    integrationOccurrenceType(
      id: $id
      enabled: $enabled
      token_header: $token_header
      token: $token
      url: $url
      api: $api
      body_new: $body_new
      body_update: $body_update
    ) {
      id
      icon
      city
      name
      office_id
      office_name
      sound_alert
      group
      monitor
      priority
      integration {
        token_header
        token
        enabled
        url
        api
        body_new
        body_update
      }
    }
  }
`;
export const integrationCity = /* GraphQL */ `
  mutation IntegrationCity(
    $enabled: Boolean!
    $key: String!
    $url: String
    $api: String!
    $token: String
    $token_header: String
    $body_new: String
    $body_update: String
    $variables: [TranslateVariableInput]
  ) {
    integrationCity(
      enabled: $enabled
      key: $key
      url: $url
      api: $api
      token: $token
      token_header: $token_header
      body_new: $body_new
      body_update: $body_update
      variables: $variables
    ) {
      key
      name
      integration {
        token_header
        token
        enabled
        url
        api
        body_new
        body_update
      }
      translate {
        key
        variables {
          name
          type
          value
        }
      }
    }
  }
`;
export const createCognitoUser = /* GraphQL */ `
  mutation CreateCognitoUser(
    $name: String!
    $email: String!
    $phone: String!
    $password: String!
    $permissions: [PermissionInput]
    $offices: [PermissionOfficeInput]
  ) {
    createCognitoUser(
      name: $name
      email: $email
      phone: $phone
      password: $password
      permissions: $permissions
      offices: $offices
    ) {
      id
      name
      phone
      email
      city
      offices {
        id
        name
        action
      }
      permissions {
        id
        name
        action
      }
      occurrences_types {
        id
        icon
        city
        name
        office_id
        office_name
        sound_alert
        group
        monitor
        priority
        integration {
          token_header
          token
          enabled
          url
          api
          body_new
          body_update
        }
      }
      occurrences_status {
        city
        name
        enabled
      }
      user_priority {
        id
        city
        name
        enabled
      }
      vaccine_status
    }
  }
`;
export const updateCognitoUser = /* GraphQL */ `
  mutation UpdateCognitoUser(
    $name: String!
    $email: String!
    $phone: String!
    $permissions: [PermissionInput]
    $offices: [PermissionOfficeInput]
  ) {
    updateCognitoUser(
      name: $name
      email: $email
      phone: $phone
      permissions: $permissions
      offices: $offices
    ) {
      id
      name
      phone
      email
      city
      offices {
        id
        name
        action
      }
      permissions {
        id
        name
        action
      }
      occurrences_types {
        id
        icon
        city
        name
        office_id
        office_name
        sound_alert
        group
        monitor
        priority
        integration {
          token_header
          token
          enabled
          url
          api
          body_new
          body_update
        }
      }
      occurrences_status {
        city
        name
        enabled
      }
      user_priority {
        id
        city
        name
        enabled
      }
      vaccine_status
    }
  }
`;
export const changePasswordCognitoUser = /* GraphQL */ `
  mutation ChangePasswordCognitoUser($email: String!, $password: String!) {
    changePasswordCognitoUser(email: $email, password: $password)
  }
`;
export const resendPasswordMobileCognitoUser = /* GraphQL */ `
  mutation ResendPasswordMobileCognitoUser(
    $user_id: String!
    $email: String!
    $password: String!
  ) {
    resendPasswordMobileCognitoUser(
      user_id: $user_id
      email: $email
      password: $password
    )
  }
`;
export const resendOperatorPassword = /* GraphQL */ `
  mutation ResendOperatorPassword($email: String!) {
    resendOperatorPassword(email: $email)
  }
`;
export const createMobileCognitoUserMPU = /* GraphQL */ `
  mutation CreateMobileCognitoUserMPU(
    $name: String!
    $email: String
    $phone: String
    $rg: String!
    $cpf: String
    $birthdate: String!
    $district: String!
    $street: String!
    $number: String!
    $complement: String
    $obs: String
    $password: String
    $url_image: String
    $user_url_image: String
    $login_by: LOGIN_BY
  ) {
    createMobileCognitoUserMPU(
      name: $name
      email: $email
      phone: $phone
      rg: $rg
      cpf: $cpf
      birthdate: $birthdate
      district: $district
      street: $street
      number: $number
      complement: $complement
      obs: $obs
      password: $password
      url_image: $url_image
      user_url_image: $user_url_image
      login_by: $login_by
    ) {
      id
      city
      district
      district_name
      name
      email
      phone
      cpf
      rg
      birthdate
      street
      number
      complement
      gender
      mpu
      obs
      last_access
      vaccine_priority
      url_profile_image
      url_image
      image
      user_url_image
      url_profile_image_by_system
      login_by
    }
  }
`;
export const updateMobileCognitoUserMPU = /* GraphQL */ `
  mutation UpdateMobileCognitoUserMPU(
    $id: String!
    $name: String!
    $email: String
    $phone: String
    $rg: String!
    $cpf: String
    $birthdate: String!
    $district: String!
    $street: String!
    $number: String!
    $complement: String
    $obs: String
    $image: String
    $url_image: String
  ) {
    updateMobileCognitoUserMPU(
      id: $id
      name: $name
      email: $email
      phone: $phone
      rg: $rg
      cpf: $cpf
      birthdate: $birthdate
      district: $district
      street: $street
      number: $number
      complement: $complement
      obs: $obs
      image: $image
      url_image: $url_image
    ) {
      id
      city
      district
      district_name
      name
      email
      phone
      cpf
      rg
      birthdate
      street
      number
      complement
      gender
      mpu
      obs
      last_access
      vaccine_priority
      url_profile_image
      url_image
      image
      user_url_image
      url_profile_image_by_system
      login_by
    }
  }
`;
export const registerMPUToMobileUser = /* GraphQL */ `
  mutation RegisterMPUToMobileUser(
    $user_id: String!
    $registration_number: String!
    $begin_date: String!
    $end_date: String!
    $obs: String
    $suspect: SuspectInput!
  ) {
    registerMPUToMobileUser(
      user_id: $user_id
      registration_number: $registration_number
      begin_date: $begin_date
      end_date: $end_date
      obs: $obs
      suspect: $suspect
    ) {
      id
      user_id
      city
      enabled
      registration_number
      begin_date
      end_date
      obs
      suspect {
        name
        phone
        url_image
        url_image_by_mobile
        image
        city
        street
        district
        suspect_address_complement
        number
        rg
        cpf
        suspect_obs
        summons_date
        suspect_veicle
      }
    }
  }
`;
export const updateMPUOfMobileUser = /* GraphQL */ `
  mutation UpdateMPUOfMobileUser(
    $id: String!
    $user_id: String!
    $registration_number: String!
    $begin_date: String!
    $end_date: String!
    $enabled: Boolean!
    $obs: String
    $suspect: SuspectInput!
  ) {
    updateMPUOfMobileUser(
      id: $id
      user_id: $user_id
      registration_number: $registration_number
      begin_date: $begin_date
      end_date: $end_date
      enabled: $enabled
      obs: $obs
      suspect: $suspect
    ) {
      id
      user_id
      city
      enabled
      registration_number
      begin_date
      end_date
      obs
      suspect {
        name
        phone
        url_image
        url_image_by_mobile
        image
        city
        street
        district
        suspect_address_complement
        number
        rg
        cpf
        suspect_obs
        summons_date
        suspect_veicle
      }
    }
  }
`;
export const updateSuspectPictureByMobile = /* GraphQL */ `
  mutation UpdateSuspectPictureByMobile($mpu_id: String!, $image: String!) {
    updateSuspectPictureByMobile(mpu_id: $mpu_id, image: $image)
  }
`;
export const createAndUpdateContactListOfUserMobile = /* GraphQL */ `
  mutation CreateAndUpdateContactListOfUserMobile(
    $contacts: [ContactPhoneInput]!
  ) {
    createAndUpdateContactListOfUserMobile(contacts: $contacts) {
      id
      name
      phone
      description
      order
    }
  }
`;
export const updateAttributeUserMobile = /* GraphQL */ `
  mutation UpdateAttributeUserMobile($key: String!, $value: String!) {
    updateAttributeUserMobile(key: $key, value: $value) {
      key
      value
    }
  }
`;
export const updateProfilePicture = /* GraphQL */ `
  mutation UpdateProfilePicture($image: String!) {
    updateProfilePicture(image: $image)
  }
`;
export const updateProfilePictureBySystem = /* GraphQL */ `
  mutation UpdateProfilePictureBySystem($id: String!, $image: String!) {
    updateProfilePictureBySystem(id: $id, image: $image)
  }
`;
export const pushVaccination = /* GraphQL */ `
  mutation PushVaccination(
    $title: String!
    $message: String!
    $vaccine_local_id: String!
    $first_age: Int!
    $second_age: Int!
    $vaccine_priority_id: String!
  ) {
    pushVaccination(
      title: $title
      message: $message
      vaccine_local_id: $vaccine_local_id
      first_age: $first_age
      second_age: $second_age
      vaccine_priority_id: $vaccine_priority_id
    )
  }
`;
export const pushCityVaccination = /* GraphQL */ `
  mutation PushCityVaccination(
    $title: String!
    $message: String!
    $first_age: Int!
    $second_age: Int!
  ) {
    pushCityVaccination(
      title: $title
      message: $message
      first_age: $first_age
      second_age: $second_age
    )
  }
`;
export const pushToDistrict = /* GraphQL */ `
  mutation PushToDistrict(
    $title: String!
    $message: String!
    $districts: [String]!
  ) {
    pushToDistrict(title: $title, message: $message, districts: $districts)
  }
`;
export const pushToRegion = /* GraphQL */ `
  mutation PushToRegion(
    $title: String!
    $message: String!
    $regions: [String]!
  ) {
    pushToRegion(title: $title, message: $message, regions: $regions)
  }
`;
export const pushToCity = /* GraphQL */ `
  mutation PushToCity($title: String!, $message: String!) {
    pushToCity(title: $title, message: $message)
  }
`;
export const createQuiz = /* GraphQL */ `
  mutation CreateQuiz(
    $name: String!
    $current: Boolean
    $questions: [QuestionInput]!
  ) {
    createQuiz(name: $name, current: $current, questions: $questions) {
      id
      name
      current
      questions {
        id
        description
        note
      }
    }
  }
`;
export const updateQuiz = /* GraphQL */ `
  mutation UpdateQuiz(
    $id: ID
    $name: String!
    $current: Boolean
    $questions: [QuestionInput]!
  ) {
    updateQuiz(id: $id, name: $name, current: $current, questions: $questions) {
      id
      name
      current
      questions {
        id
        description
        note
      }
    }
  }
`;
export const setPandemicFlagToCity = /* GraphQL */ `
  mutation SetPandemicFlagToCity($flag: PANDEMIC_FLAG) {
    setPandemicFlagToCity(flag: $flag) {
      id
      name
      state
      integrations {
        key
        name
        integration {
          token_header
          token
          enabled
          url
          api
          body_new
          body_update
        }
        translate {
          key
          variables {
            name
            type
            value
          }
        }
      }
      flag
    }
  }
`;
export const answerQuiz = /* GraphQL */ `
  mutation AnswerQuiz($quiz: ID!, $answers: [AnswerInput]!, $note: String) {
    answerQuiz(quiz: $quiz, answers: $answers, note: $note)
  }
`;
export const createOffice = /* GraphQL */ `
  mutation CreateOffice($name: String!) {
    createOffice(name: $name) {
      id
      name
      occurrences
    }
  }
`;
export const updateOffice = /* GraphQL */ `
  mutation UpdateOffice($id: ID!, $name: String!) {
    updateOffice(id: $id, name: $name) {
      id
      name
      occurrences
    }
  }
`;
export const registerTokenDevice = /* GraphQL */ `
  mutation RegisterTokenDevice($token: String!, $device: String!) {
    registerTokenDevice(token: $token, device: $device)
  }
`;
export const createAndUpdatePointOnMap = /* GraphQL */ `
  mutation CreateAndUpdatePointOnMap($input: PointOnMapInput) {
    createAndUpdatePointOnMap(input: $input) {
      id
      name
      address
      address_number
      lat
      long
      type
      opening_hours {
        monday {
          am
          pm
        }
        tuesday {
          am
          pm
        }
        wednesday {
          am
          pm
        }
        thursday {
          am
          pm
        }
        friday {
          am
          pm
        }
        saturday {
          am
          pm
        }
        sunday {
          am
          pm
        }
      }
      phone
      url_img
      url_site
      vaccine_local_id
    }
  }
`;
export const createAndUpdateVaccinationPointOnMap = /* GraphQL */ `
  mutation CreateAndUpdateVaccinationPointOnMap(
    $input: VaccinationPointOnMapInput
  ) {
    createAndUpdateVaccinationPointOnMap(input: $input) {
      id
      name
      enabled
      quantityOfVaccines
      quantityOfAgents
      peopleToVaccinate
      vaccinatedPeople
      quantityPeoplesOnPoint
      low_age
      high_age
      address
      address_number
      lat
      long
    }
  }
`;
export const createAndUpdateNewsFeed = /* GraphQL */ `
  mutation CreateAndUpdateNewsFeed($id: String, $city: String) {
    createAndUpdateNewsFeed(id: $id, city: $city)
  }
`;
export const deleteNewsFeed = /* GraphQL */ `
  mutation DeleteNewsFeed($id: String) {
    deleteNewsFeed(id: $id)
  }
`;
export const deleteMobileUser = /* GraphQL */ `
  mutation DeleteMobileUser($id: String) {
    deleteMobileUser(id: $id)
  }
`;
export const deleteMobileUserWithMpu = /* GraphQL */ `
  mutation DeleteMobileUserWithMpu($id: String, $city: String) {
    deleteMobileUserWithMpu(id: $id, city: $city)
  }
`;
export const createMobileDeletionRequest = /* GraphQL */ `
  mutation CreateMobileDeletionRequest($id: String) {
    createMobileDeletionRequest(id: $id)
  }
`;
