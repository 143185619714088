import {Component, Input, OnInit} from '@angular/core';
import {History} from '../../../model/history';
import {ImageDialogComponent} from '../../../../pages/occurrences/image-dialog/image-dialog.component';
import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.scss']
})
export class HistoryListComponent implements OnInit {

  @Input() historic: Array<History>;

  constructor(
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
  }

  openImage(position) {
    this.dialog.open(ImageDialogComponent, {
      data: this.historic[position].url_image
    });
  }
}
