<app-main-nav-web [pagesPermission]="pagesPermission" *ngIf="(isLandscape$ | async); else web">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</app-main-nav-web>


<ng-template #web>
  <app--main-nav-mobile [pagesPermission]="pagesPermission">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </app--main-nav-mobile>
</ng-template>


<ng-template #content>
  <ng-content></ng-content>
</ng-template>
