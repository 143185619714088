import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';


import {AmplifyService} from 'aws-amplify-angular';

import {fromPromise} from 'rxjs/internal-compatibility';

import {AuthService} from '../../auth/auth.service';

import {FormOmbudsmanComponent} from '../../../pages/ombudsman/form-omdubsman/form-ombudsman.component';
import {FormOccurrenceComponent} from '../../../pages/occurrences/form-occurrence/form-occurrence.component';
import {FormOccurrencesGroupedComponent} from '../../../pages/occurrences-grouped/form-occurrences-grouped/form-occurrences-grouped.component';

import {ModalInfoComponent} from '../modal-info/modal-info.component';

@Component({
  selector: 'app-main-nav-web',
  templateUrl: './main-nav-web.component.html',
  styleUrls: ['./main-nav-web.component.scss']
})
export class MainNavWebComponent implements OnInit {
  @ViewChild('account') account : ElementRef;
  @Input()
  pagesPermission: Array<string> = [];

  ticket;
  occurrence;
  vaccination;
  occurrenceGroup;

  constructor(
    private amplifyService: AmplifyService,
    public router: Router,
    public  authService: AuthService,
    private occurrenceEmiter: FormOccurrenceComponent,
    private occurrenceGroupedEmiter: FormOccurrencesGroupedComponent,
    private dialog: MatDialog,
  ) {

  }

  ngOnInit() {
    FormOccurrenceComponent.occurrenceEmitter.subscribe(
      occurrence => this.occurrence = occurrence.id,
    )
    FormOccurrencesGroupedComponent.occurrenceGroupedEmitter.subscribe(
      occurrence => this.occurrenceGroup = occurrence.geo_group_id,
    )

    FormOmbudsmanComponent.ticketEmitter.subscribe(
      ticket => this.ticket = ticket.id,
    )
  }

  /**
   * Verifica se usuario tem permissao para acessar pagina
   * @param pages Array<string> id das paginas que serao verificadas
   */
  hasPermission(pages: Array<string>): boolean {
    for (const page of pages) {
      if (this.pagesPermission.includes(page)) {
        return true;
      }
    }
    return false;
  }

  logoutBtn() {
    fromPromise(this.amplifyService.auth().signOut())
      .subscribe(() => this.router.navigate(['/login']));
    this.authService.loggedUser = false;
  }

  openModal() {
    const dialogRef = this.dialog.open(ModalInfoComponent, {
      width: '725px',
    });
  }

  editUser(){
    this.authService.getCurrentUser().then(item =>{
      this.router.navigate([`/settings/users/${item.data.getCurrentCognitoUser.id}`])
    })
  }
}
