import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';


import {ConnectionService} from 'ng-connection-service';


@Injectable({
  providedIn: 'root'
})
export class ConnectionCheckService {

  constructor(
    private connectionCheck: ConnectionService,
    private snackBar: MatSnackBar
  ) {
    let alertString: string;
    this.connectionCheck.monitor().subscribe((hasConnection) => {
      alertString = hasConnection ? 'Conexão com a internet restabelecida' : 'Conexão com a internet perdida';
      this.snackBar.open(alertString, 'OK', {
        duration: 4000,
      });
    });
  }
}
