import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading-spinner-button-icon',
  templateUrl: './loading-spinner-button-icon.component.html',
  styleUrls: ['./loading-spinner-button-icon.component.scss']
})
export class LoadingSpinnerButtonIconComponent implements OnInit {

  @Input()
  color = 'accent';

  @Input()
  disabled = false;

  @Input()
  loading: false;

  constructor() {
  }

  ngOnInit() {
  }

}
