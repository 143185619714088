// import 'hammerjs';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import Amplify, {Auth} from 'aws-amplify';
import awsmobile from './aws-exports';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


if (environment.production) {

  environment.version = 'P - 2.2'

  // PRODUCAO
  Amplify.configure(awsmobile);

  Amplify.configure({
    aws_appsync_graphqlEndpoint: 'https://yjbdbsibd5cbnoef77fmev5wda.appsync-api.us-east-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'da2-c23srfnkdvb3jio35ieuliumzq',
  });

  Amplify.configure({
    Auth: {
      identityPoolId: 'us-east-2:728fc6e7-45de-4b60-853d-8237766a617b',
      region: 'us-east-2',
      userPoolId: 'us-east-2_xZdr0jAcY',
      userPoolWebClientId: '604nn2qcsn73ntm6a5bqrt2jup'
    },
    API: {
      graphql_headers: async () => {
        return {Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()};
      }
    }
  });

} else {

  environment.version = 'D - 2.2'

  // DESENVOLVIMENTO
  Amplify.configure(awsmobile);

  Amplify.configure({
    aws_appsync_graphqlEndpoint: 'https://yjbdbsibd5cbnoef77fmev5wda.appsync-api.us-east-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'da2-c23srfnkdvb3jio35ieuliumzq',
  });

  Amplify.configure({
    Auth: {
      identityPoolId: 'us-east-2:728fc6e7-45de-4b60-853d-8237766a617b',
      region: 'us-east-2',
      userPoolId: 'us-east-2_xZdr0jAcY',
      userPoolWebClientId: '604nn2qcsn73ntm6a5bqrt2jup'
    },

    API: {
      graphql_headers: async () => {
        return {Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()};
      }
    }
  });
}
