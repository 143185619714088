<mat-progress-bar *ngIf="loading" mode="indeterminate" color="accent"></mat-progress-bar>

<app-header-page backButtonPath="/occurrences/list">
    <span *ngIf="!loading; else titleLoading">
    {{ occurrence.type_name || "Ocorrência" }}
    </span>
  <ng-template #titleLoading>carregando...</ng-template>
</app-header-page>

<div class="container no-margin-bottom">
  <div class="row">
    <div class="col-md-4">
      <mat-tab-group mat-stretch-tabs style="overflow: hidden" animationDuration="0ms">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon svgIcon="pencil" matTooltip="Ocorrência"></mat-icon>
          </ng-template>

          <ng-template matTabContent>
            <mat-card>

              <mat-card class="image-box" style="padding-left: 0">
                <img mat-card-image src="{{occurrence.url_image}}" alt="Foto da ocorrencia"
                     class="image" *ngIf="occurrence.url_image" (click)="openImage()">
                <img mat-card-image id="no-image" src="./assets/img/picture.svg" alt="Carregando foto"
                     *ngIf="!occurrence.url_image">
              </mat-card>

              <mat-divider class="margin"></mat-divider>

              <form (keydown.enter)="false" [formGroup]="editForm" #documentEditForm="ngForm"
                    (ngSubmit)="onSubmitForm()" novalidate>
                <mat-card-content class="margin-top">
                  <div class="row">
                    <div class="col">
                      <mat-form-field id="space-divider">
                        <mat-select formControlName="status" placeholder="Status">
                          <mat-option *ngFor="let status of statusOptions"
                                      [value]="status.name">
                            {{status.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <mat-form-field>
                        <mat-select formControlName="priority" placeholder="Prioridade">
                          <mat-option *ngFor="let priority of appCommon.priorityOptions"
                                      [value]="priority.value">
                            {{priority.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <mat-form-field>
                        <input matInput formControlName="external_protocol" placeholder="CAD">
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <mat-form-field>
                        <input matInput formControlName="completion_date"
                               [min]="minDate"
                               [max]="maxDate"
                               [matDatepicker]="picker"
                               maxlength="8"
                               placeholder="Previsão de encerramento" appMaskDate>
                        <mat-datepicker-toggle matSuffix [for]="picker" disabled></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error>Data de encerramento inválida.</mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <mat-form-field>
                        <textarea class="hidden-arrows" matInput cdkTextareaAutosize formControlName="note"
                                  placeholder="Observação pública."></textarea>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row" *ngIf="!occurrence.closed">

                    <div class="col margin-top">
                      <mat-slide-toggle labelPosition="before" formControlName="closed">
                        Finalizar
                      </mat-slide-toggle>
                    </div>

                    <div class="col-2">
                      <input formControlName="image" type="file" style="display: none" (change)="handleUpload($event)"
                             #fileInput accept="image/*" novalidate/>
                      <button mat-icon-button class="float-right" [disabled]="editForm.disabled"
                              (click)="fileInput.click()" type="button">
                        <mat-icon svgIcon="searchImage" class="shadow-none
                                  {{editForm.disabled ? 'disabled' : ''}}
                                  {{ editForm.controls.image.value === null ? 'icon-null' : ''}}"
                                  matTooltip="{{image_link ? image_link : 'Inserir imagem'}}"
                                  color="primary" novalidate>
                        </mat-icon>
                      </button>
                    </div>

                  </div>

                  <div class="col">
                    <mat-label></mat-label>
                  </div>

                  <div class="row" *ngIf="occurrence.closed">
                    <div class="col margin-top">
                      <strong>Finalizado</strong>
                    </div>
                  </div>
                </mat-card-content>
              </form>

              <mat-card-actions *ngIf="canEdit && !loading">
                <p *ngIf="loadingEditForm" style="margin-left: 16px;">Carregando...</p>

                <button *ngIf="(editForm.disabled && !loadingEditForm) && !occurrence.closed" mat-button
                        (click)="editForm.enable()">EDITAR
                </button>

                <button *ngIf="editForm.enabled" mat-button [disabled]="!editForm.dirty"
                        (click)="documentEditForm.ngSubmit.emit()">SALVAR
                </button>

                <button *ngIf="editForm.enabled && !!occurrence.id" mat-button (click)="resetForm()">CANCELAR</button>

              </mat-card-actions>
            </mat-card>
          </ng-template>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon svgIcon="account" matTooltip="Usuário"></mat-icon>
          </ng-template>

          <ng-template matTabContent>
            <mat-card>
              <mat-card-content>
                <div class="row">
                  <div class="col">
                    <strong>Nome: </strong> {{  loading ? 'carregando...' : occurrence.user_name ? occurrence.user_name : 'Não informado'}}
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <strong>E-mail: </strong>
                    {{ loading ? 'carregando...' : occurrence.user_email }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <strong>Telefone: </strong>
                    {{ loading ? 'carregando...' : occurrence.user_phone ? (occurrence.user_phone | mask:"(00) 0 0000-0000") : 'Não informado.' }}
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </ng-template>
        </mat-tab>

        <mat-tab [disabled]="occurrence.historic.length === 0">
          <ng-template mat-tab-label>
            <mat-icon svgIcon="history" matTooltip="Histórico"></mat-icon>
          </ng-template>

          <ng-template matTabContent style="overflow-x: hidden; ">
            <div style="height: calc(100vh - 160px) !important;margin-top: 5px"
                 class="h-50 h-content-50 h-content-100">
              <app-history-list [historic]="occurrence.historic"></app-history-list>
            </div>
          </ng-template>
        </mat-tab>

      </mat-tab-group>
    </div>
    <div class="col-md-8">
      <div class="row">
        <div class="col-12 mat-h4 text-justify no-margin-bottom">
          <strong>Descrição:</strong> {{ loading ? 'carregando...' : occurrence.description }}
        </div>
        <div class="col-12 mat-h4 text-justify no-margin-bottom">
          <strong
            class="{{loading? 'align-endereco' : ''}}">Endereço:</strong> {{ loading ? 'carregando...' : occurrence.address }}
        </div>
        <div class="col-12 mat-h4 text-justify no-margin-bottom">
          <strong
            class="{{loading? 'align-endereco' : ''}}">CAD:</strong> {{ loading ? 'carregando...' : occurrence?.last_protocol ? occurrence?.last_protocol : 'Não informado...'}}
        </div>
        <div class="col-12 mat-h4 text-justify no-margin-bottom">
          <strong
            class="{{loading? 'align-endereco' : ''}}">Protocolo:</strong> {{ loading ? 'carregando...' : occurrence?.last_protocol ? occurrence?.last_protocol : 'Não informado...'}}
        </div>
      </div>

      <div class="row justify-content-between">
        <div class="col-md-7 no-margin-bottom  mat-h4 text-justify">
          <strong>Secretaria:</strong> {{loading ? 'carregando...' : occurrence.office_name}}
        </div>

        <!--        <div class="col-md-auto col-sm-auto ml-lg-auto">-->
        <div class="col-auto">

          <ng-container
            *ngIf="occurrence.protocol !== null || loading; else createProtocol ">
            <div class="row">
              <div class="col">
                <strong>Protocolo:</strong>
                {{loading ? 'carregando...' : occurrence.protocol}}
              </div>
            </div>
          </ng-container>

          <ng-template #createProtocol>
            <form [formGroup]="protocolForm" (ngSubmit)="onSubmitFormProtocol()" *ngIf="!occurrence.closed" novalidate>
              <div class="row">
                <div class="col-auto ml-md-auto">

                  <mat-form-field floatLabel="never" color="accent" id="input-protocol">
                    <input matInput formControlName="protocol" placeholder="Insira o novo protocolo">
                  </mat-form-field>

                </div>

                <div class="col-auto col-sm-auto button-protocol">
                  <app-loading-spinner-button-icon
                    [disabled]="protocolForm.invalid || protocolForm.disabled"
                    [loading]="loadingProtocolform"></app-loading-spinner-button-icon>
                </div>

              </div>
            </form>
          </ng-template>

        </div>
      </div>

      <div class="row justify-content-md-end justify-content-sm-start text-subtitle-color">
        <div class="col-md col-sm-12">
          Data de abertura: {{ loading ? 'carregando...' : occurrence.date_started | date:"dd/MM/yyy HH:mm:ss" }}
        </div>
      </div>

      <mat-tab-group mat-stretch-tabs mat-align-tabs="center">
        <mat-tab>
          <ng-template mat-tab-label>
            Interações
          </ng-template>

          <ng-template matTabContent>
            <mat-card class="margin-top">
              <mat-card-content>
                <form [formGroup]="noteForm" #documentFormNote="ngForm" (ngSubmit)="onSubmitFormNote()" novalidate>
                  <div class="row">
                    <div class="col">
                      <mat-form-field appearance="outline" class="no-margin">
                        <mat-label> Observação interna</mat-label>
                        <textarea class="hidden-arrows" matInput cdkTextareaAutosize formControlName="note"
                                  placeholder="Descrição do atendimento ou ação que foi tomada."></textarea>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-auto ml-auto text-right btn-right">
                      <app-loading-spinner-button-add [noteForm]="noteForm" [spinnerLoading]="spinnerLoading"
                                                      [documentFormNote]="documentFormNote"></app-loading-spinner-button-add>
                    </div>
                  </div>
                </form>
              </mat-card-content>
            </mat-card>
            <div class="container-fluid">
              <div class="row margin-top" *ngIf="occurrence.notes.length > 0">
                <div class="col">
                  <app-note-list [notes]="occurrence.notes"></app-note-list>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Mapa">
          <ng-template matTabContent>

            <agm-map [disableDefaultUI]="true" [mapDraggable]="false" [zoom]="15"
                     [latitude]="convertString(occurrence.lat)"
                     [longitude]="convertString(occurrence.lgt)">
              <agm-marker [latitude]="convertString(occurrence.lat)"
                          [longitude]="convertString(occurrence.lgt)"
                          [iconUrl]="getIcon(occurrence.icon)"
                          [markerClickable]="false">
              </agm-marker>

            </agm-map>

          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
