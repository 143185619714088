<ng-container class="scroll">
  <mat-card-content  style="overflow:auto; overflow-x: hidden;">

    <div class="row">
      <div class="col">
        <strong>Nome: </strong> {{  loading ? 'carregando...' : data.user_name ? data.user_name : 'Não informado'}}
      </div>
    </div>

    <div class="row">
      <div class="col">
        <strong>E-mail: </strong>
        {{ loading ? 'carregando...' : data.user_email }}
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <strong>Telefone: </strong>
        {{ loading ? 'carregando...' : data.user_phone ? (data.user_phone | mask:"(00) 0 0000-0000") : 'Não informado.' }}
      </div>
    </div>

    <mat-divider style="margin: 10px 0px 10px 0px"></mat-divider>

    <div class="row" *ngIf="userContacts.length > 0" >
      <div class="col-12">
        <p style="color: gray; font-size: 20px">Contatos</p>

        <div class="row"  *ngFor="let contacts of userContacts">

          <div class="col-6">
            <strong>Nome: </strong>{{contacts.name}}
          </div>

          <div class="col-6">
            <strong>Telefone: </strong>{{contacts.phone | mask:"(00) 0 0000-0000"}}
          </div>

        </div>
      </div>
    </div>
  </mat-card-content>
</ng-container>
