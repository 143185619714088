<app-main-nav *ngIf="authService.loggedUser; else notLogged">
  <div class="content">
    <router-outlet *ngIf="!loadingModule"></router-outlet>
    <ng-container *ngIf="loadingModule"><app-logo-loading></app-logo-loading></ng-container>
  </div>
</app-main-nav>

<ng-template #notLogged>
  <router-outlet *ngIf="!loadingModule"></router-outlet>
  <ng-container *ngIf="loadingModule"><app-logo-loading></app-logo-loading></ng-container>
</ng-template>
