import {Generic} from './generic';

export class Ticket extends Generic {
  subject: string;
  extended: boolean; // prorrogado
  identified: boolean;
  cpf: string;
  open_days: string;
  external_protocol: string;
}



