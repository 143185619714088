import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AmplifyService} from 'aws-amplify-angular';
import {fromPromise} from 'rxjs/internal-compatibility';
import {catchError, map} from 'rxjs/operators';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private amplifyService: AmplifyService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return fromPromise(this.amplifyService.auth().currentAuthenticatedUser({bypassCache: true}))
      .pipe(
        map(resp => {
          const user: any = resp;
          this.authService.loadUser(user);
          return true;
        }),
        catchError(error => {
          console.log(error);
          this.amplifyService.auth().signOut();
          this.router.navigate(['/login']);
          return of(false);
        })
      );
    return fromPromise(this.amplifyService.auth().refreshToken({bypassCache: true}))
      .pipe(
        map(resp => {
          const user: any = resp;
          this.authService.loadUser(user);
          return true;
        }),
        catchError(error => {
          console.log(error);
          this.amplifyService.auth().signOut();
          this.router.navigate(['/login']);
          return of(false);
        })
      );
  }

}
