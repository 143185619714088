import {Component} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {AuthService} from '../auth/auth.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent {

  private pagesPermission: Array<string> = [];
  isLandscape$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.TabletLandscape, Breakpoints.WebLandscape])
    .pipe(
      map(result => result.matches)
    );


  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
  ) {
    // busca as telas que o usuario tem permissao
    this.authService.permissions.subscribe(permissions => {
        if (permissions) {
          this.pagesPermission = permissions.map(item => item.name);
        }
      }
    );
  }


}
