<mat-progress-bar *ngIf="loading" mode="indeterminate" color="accent"></mat-progress-bar>

<app-header-page backButtonPath="/ombudsman">
    <span *ngIf="!loading; else titleLoading">
    {{ ticket.type_name || "Ticket" }}
    </span>
  <ng-template #titleLoading>carregando...</ng-template>
</app-header-page>

<div class="container no-margin-bottom">
  <div class="row" >
    <div class="col-md-4" >

      <mat-tab-group mat-stretch-tabs  style="overflow:hidden;" animationDuration="0ms">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon svgIcon="pencil" matTooltip="Ticket"></mat-icon>
          </ng-template>

          <ng-template matTabContent>
            <mat-card>
              <form [formGroup]="editForm" #documentEditForm="ngForm" (ngSubmit)="onSubmitForm()" novalidate>
                <mat-card-content>

                  <div class="row">
                    <div class="col">
                      <mat-form-field>
                        <mat-select formControlName="status" placeholder="Status">
                          <mat-option *ngFor="let status of statusOptions"
                                      [value]="status.name">
                            {{status.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <mat-form-field>
                        <mat-select formControlName="priority" placeholder="Prioridade">
                          <mat-option *ngFor="let priority of appCommon.priorityOptions"
                                      [value]="priority.value">
                            {{priority.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <mat-form-field>
                        <input matInput formControlName="completion_date"
                               [matDatepicker]="picker"
                               [min]="minDate"
                               [max]="maxDate"
                               [matDatepicker]="picker"
                               maxlength="8"
                               placeholder="Previsão de encerramento">
                        <mat-datepicker-toggle matSuffix [for]="picker" disabled></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <mat-form-field>
                        <textarea matInput cdkTextareaAutosize formControlName="note" class="hidden-arrows"
                                  placeholder="Observação pública."></textarea>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row" *ngIf="!ticket.closed && canEdit">
                    <div class="col margin-top">
                      <mat-slide-toggle labelPosition="before" formControlName="closed">
                        Finalizar
                      </mat-slide-toggle>
                    </div>
                  </div>

                  <div class="row" *ngIf="ticket.closed">
                    <div class="col margin-top">
                      <strong>Finalizado</strong>
                    </div>
                  </div>

                </mat-card-content>
              </form>

              <mat-card-actions *ngIf="!loading">
                <p *ngIf="loadingEditForm" style="margin-left: 16px;">Carregando...</p>

                <button *ngIf="(editForm.disabled && !loadingEditForm) && !ticket.closed" mat-button
                        (click)="editForm.enable()">EDITAR
                </button>
                <button *ngIf="editForm.enabled && !!ticket.id" mat-button (click)="resetForm()">CANCELAR</button>
                <button *ngIf="editForm.enabled" mat-button [disabled]="!editForm.dirty"
                        (click)="documentEditForm.ngSubmit.emit()">SALVAR
                </button>
              </mat-card-actions>

            </mat-card>
          </ng-template>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon svgIcon="account" matTooltip="Usuário"></mat-icon>
          </ng-template>

          <ng-template matTabContent>
            <mat-card>
              <mat-card-content>
                <div class="row" *ngIf="!ticket.identified">
                  <div class="col">
                    <strong>Solicitou sigilo de identidade </strong>
                  </div>
                  <div class="col-auto ml-auto">
                    <button id="eye-button" mat-icon-button (click)="showingIdentity = !showingIdentity"
                            matTooltip="{{ !showingIdentity && !ticket.identified ? 'Mostrar' : 'Ocultar'}} identidade">
                      <mat-icon
                        svgIcon="{{ !showingIdentity && !ticket.identified ? 'eye' : 'eye-closed' }}"></mat-icon>
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <strong>Nome: </strong>
                    <strong *ngIf="!showingIdentity && !ticket.identified; else showName">***************</strong>
                    <ng-template #showName>
                      {{  ticket.user_name ? ticket.user_name : 'Não informado'}}
                    </ng-template>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <strong>E-mail: </strong>
                    <strong *ngIf="!showingIdentity && !ticket.identified; else showEmail">***************</strong>
                    <ng-template #showEmail>
                      {{ ticket.user_email }}
                    </ng-template>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <strong>Telefone: </strong>
                    <strong *ngIf="!showingIdentity && !ticket.identified; else showPhone">***************</strong>
                    <ng-template #showPhone>
                      {{ ticket.user_phone ? (ticket.user_phone | mask:"(00) 0 0000-0000") : 'Não informado.' }}
                    </ng-template>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </ng-template>
        </mat-tab>

        <mat-tab [disabled]="ticket.historic.length === 0">
          <ng-template mat-tab-label>
            <mat-icon svgIcon="history" matTooltip="Histórico"></mat-icon>
          </ng-template>

          <ng-template matTabContent style="overflow-x: hidden">

            <div style="height: calc(100vh - 160px) !important;" class="h-50 h-content-50 h-content-100" >
                <app-history-list [historic]="ticket.historic"></app-history-list>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="col-md-8">

      <div class="row">
        <div class="col-12 mat-h2 no-margin-bottom"><strong>{{ loading ? 'carregando...' : ticket.subject }}</strong>
        </div>
      </div>

      <div class="row">
        <div
          class="col-12 mat-h4 text-justify no-margin-bottom">{{ loading ? 'carregando...' : ticket.description }}
        </div>
      </div>

      <div class="row">
        <div class="col">
          <ng-container *ngIf="ticket.protocol !== null || loading; else createProtocol">
            <div class="row">
              <div class="col">
                <strong>Protocolo:</strong>
                {{loading ? 'carregando...' : ticket.protocol}}
              </div>
            </div>
          </ng-container>

          <ng-template #createProtocol>
            <form [formGroup]="protocolForm" (ngSubmit)="onSubmitFormProtocol()" novalidate *ngIf="!ticket.closed">
              <div class="row">
                <div class="col-auto">
                  <mat-form-field floatLabel="never" color="accent" id="input-protocol">
                    <input matInput formControlName="protocol" placeholder="Insira o novo protocolo">
                  </mat-form-field>
                </div>

                <div class="col-auto button-protocol">
                  <app-loading-spinner-button-icon
                    [disabled]="protocolForm.invalid || protocolForm.disabled"
                    [loading]="loadingProtocolForm">
                  </app-loading-spinner-button-icon>

                </div>
              </div>
            </form>
          </ng-template>

        </div>
      </div>

      <div class="row justify-content-md-end justify-content-sm-start text-subtitle-color">
        <div class="col-md col-sm-12 margin-top-data">
          Data de abertura: {{ loading ? 'carregando...' : ticket.date_started | date:"dd/MM/yyy HH:mm:ss" }}
        </div>
      </div>

      <div class="row margin-divider">
        <div class="col">
          <mat-divider></mat-divider>
        </div>
      </div>

      <mat-card>
        <mat-card-content>
          <form [formGroup]="noteForm" #documentFormNote="ngForm" (ngSubmit)="onSubmitFormNote()" novalidate>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline" class="no-margin">
                  <mat-label> Observação interna</mat-label>
                  <textarea matInput cdkTextareaAutosize formControlName="note"
                            placeholder="Descrição do atendimento ou ação que foi tomada."></textarea>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-md-auto ml-auto text-right btn-right">
                <app-loading-spinner-button-add [noteForm]="noteForm" [spinnerLoading]="spinnerLoading"
                                                [documentFormNote]="documentFormNote"></app-loading-spinner-button-add>
              </div>
            </div>
          </form>
        </mat-card-content>
      </mat-card>

      <div class="row margin-top" *ngIf="ticket.notes.length > 0">
        <div class="col">
          <app-note-list [notes]="ticket.notes"></app-note-list>
        </div>
      </div>
    </div>
  </div>
</div>
