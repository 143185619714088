import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private temp: any = {};

  constructor(private activateRoute: Router) {
  }

  /**
   * salva a lista em um arquivo compartilhado, para utilizar como temp.
   * @param object (Temp)
   */
  save(object: Temp) {
    this.temp[this.activateRoute.url] = object;
  }

  get(): Temp {
    const id = this.activateRoute.url;
    if (this.temp.hasOwnProperty(id)) {
      return this.temp[id];
    } else {
      return null;
    }
  }

  /**
   * Atualiza o objeto no cache
   * @param object (any)
   */
  update(object: any) {
    const id = this.activateRoute.url.substring(0, (this.activateRoute.url.length - (object.id.length + 1)));
    if (this.temp.hasOwnProperty(id)) {
      const temp: Temp = this.temp[id];
      const itemIndex = temp.result.list.findIndex(item => item.id === object.id);
      this.temp[id].result.list[itemIndex] = object;

    } else {
      return null;
    }
  }

}

interface Temp {
  form: any;
  result: {
    list: any[]
    lastItem: string
    count: number
  };
}
